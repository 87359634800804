import React, {useContext, useEffect, useMemo, useState} from "react";
import {IconButton, Modal, Spinner} from "@nike/eds";
import {StructuredLog} from "../../graphql/api";
import {
    FilterableTable,
    formatDate,
    SnackbarContext,
    SnackbarStatus,
    DownloadContext
} from "node.glds-react-component-library";
import {extractPropertiesMapFromString, StructuredLogsService} from "../shared/StructuredLogsService";
import {oktaAuth} from "../config/OktaConfig";

const cupsJobUrl: string | undefined = process.env.REACT_APP_CUPS_JOB_URL;

function PrintRequestDetailOverviewDocumentEvents(props) {

    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const snackbarCtx = useContext(SnackbarContext);
    const downloadCtx = useContext(DownloadContext);
    const [loadingId, setLoadingId] = useState('');
    const structuredLogsService: StructuredLogsService = props.structuredLogsService;
    const emptyArrayOfEvents: StructuredLog[] = [];
    const events = props.events;
    const [data, setData] = useState(emptyArrayOfEvents);
    const labelPreviewUrl: string | undefined = process.env.REACT_APP_LABEL_PREVIEW_URL;
    const [propertiesMap, setPropertiesMap] = useState(new Map<string, string>());
    const [showPropertiesModal, setShowPropertiesModal] = useState(false);

    const generateEventLink = (props) => {
        const propValue = props.value; // An example value is 'CUPS accepted request and created job {{47[CUPS_JOB_URL:jobId|cupsInstance]}}'

        const placeHolder = propValue.substring(propValue.indexOf("{{") + 2, propValue.indexOf("}}"));
        const beforePlaceHolder = propValue.substring(0, propValue.indexOf("{{"));
        const afterPlaceHolder = propValue.substring(propValue.indexOf("}}") + 2, propValue.length);
        const placeHolderText = placeHolder.substring(0, placeHolder.indexOf("["));
        const placeHolderParameters = placeHolder.substring(placeHolder.indexOf(":") + 1, placeHolder.indexOf("]"));

        let cupsJobUrlVariablesReplaced: string = cupsJobUrl as string;

        let propertiesMap: Map<string, string> = extractPropertiesMapFromString(props.row.original.properties);
        placeHolderParameters.split('|').forEach(function (parameter) {
            const parameterValue = propertiesMap.get(parameter);
            if (parameterValue !== null && parameterValue !== undefined) {
                cupsJobUrlVariablesReplaced = cupsJobUrlVariablesReplaced.replace('{{' + parameter + '}}', parameterValue);
            }
        });

        return beforePlaceHolder + '<a class="eds-link" href="' + cupsJobUrlVariablesReplaced + '">' + placeHolderText + '</a> ' + afterPlaceHolder;
    }
    const downloadPayload = async (e, {row}) => {
        if (row.original.payload === undefined) {
            setDownloading(true);
            setButtonLoading(row.id);
            const region = row.original.payloadRegion;
            const bucket = row.original.payloadBucket;
            const key = row.original.payloadPath;
            const version = row.original.payloadVersion;
            await structuredLogsService
                .getPayload(region, bucket, key, version)
                .then((result) => {
                    row.original.payload = result.data.getPayload.content;
                    row.original.preSignedUrl = result.data.getPayload.preSignedUrl;
                    row.original.contentType = result.data.getPayload.contentType;
                    row.original.contentEncoding = result.data.getPayload.contentEncoding;
                })
                .catch((error) => {
                    snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
                })
                .finally(() => {
                    setDownloading(false);
                    setLoadingId('');
                });
        }
    };

    function determineContentType(original: any) {
        if (original.payloadPath.endsWith(".zpl")) {
            return "application/zpl";
        }
        return original.contentType;
    }

    const handleViewClicked = async (e, props) => {
        await downloadPayload(e, props);
        downloadCtx.displayModal(props.row.original.payload, props.row.original.payloadPath, props.row.original.preSignedUrl, determineContentType(props.row.original), props.row.original.contentEncoding, labelPreviewUrl!, oktaAuth.getAccessToken()!);
    };

    const handlePropertiesViewClicked = async (map: Map<string, string>) => {
        setPropertiesMap(map)
        setShowPropertiesModal(true);
    };

    const setButtonLoading = (id) => setLoadingId(id);

    const columns = useMemo(() => [
        {
            Header: "Timestamp",
            accessor: "timestamp",
            Cell: (props) => {
                return (
                    <>
                        {formatDate(props.value)}
                    </>
                );
            }
        },
        {
            Header: "Duration(ms)", accessor: "duration"
        },
        {
            Header: "Service", accessor: "componentName"
        },
        {
            Header: "Region", accessor: "region"
        },
        {
            Header: "Error description", accessor: "errorDescription"
        },
        {
            Header: "Message", accessor: "message",
            Cell: (props) => {
                return (
                    (props.value && props.value.indexOf("{{") > -1) ?
                        <>
                            <div dangerouslySetInnerHTML={{__html: generateEventLink(props)}}/>
                        </>
                        :
                        <>
                            {props.value}
                        </>
                );
            }
        },
        {
            Header: "", accessor: "payloadPath",
            Cell: (props) => {
                let payloadPath = props.value;
                let propertiesMap: Map<string, string> = extractPropertiesMapFromString(props.row.original.properties);
                return (

                    <>
                        <span style={{visibility: propertiesMap.size > 0 ? "initial" : "hidden"}}>
                            <IconButton
                                id={props.row.id}
                                size={"small"}
                                icon="Whiteboard"
                                label={"View"}
                                onClick={(e) => handlePropertiesViewClicked(propertiesMap)}
                                disabled={downloading}
                            />
                        </span>
                        <span
                            style={{visibility: payloadPath !== undefined && payloadPath !== null ? "initial" : "hidden"}}>
                            {loadingId === props.row.id ? (
                                <div style={{padding: '6px'}}>
                                    <Spinner color="white"/>
                                </div>
                            ) : (
                                <IconButton
                                    id={props.row.id}
                                    size={"small"}
                                    icon="Expand"
                                    label={"View"}
                                    onClick={(e) => handleViewClicked(e, props)}
                                    disabled={downloading}
                                />

                            )}
                        </span>
                    </>
                );
            }
        }
    ], [loading, downloading, loadingId]); // eslint-disable-line react-hooks/exhaustive-deps

    function getRowProps(row) {
        return {
            className: row.original.errorDescription ? 'eds-table-row errorStatus' : '',
        };
    }

    useEffect(() => {
        if (events) {
            setLoading(false);
            //array is ordered on timestamp newest to oldest
            let previousTimestamp = null;
            for (let item of events.filter(item => item.type === "Event").slice().reverse()) {
                if (previousTimestamp !== null && previousTimestamp !== undefined) {
                    var utc1 = new Date(previousTimestamp);
                    var utc2 = new Date(item.timestamp)
                    item.duration = Math.floor((utc2.getTime() - utc1.getTime()));
                    previousTimestamp = item.timestamp;
                } else {
                    previousTimestamp = item.timestamp;
                    item.duration = 0;
                }
            }
            setData(events);
        }
    }, [events]);

    return (
        <div hidden={props.activeId !== 'documentEventsTab'}>
            <FilterableTable data={data} columns={columns} loading={loading} rowProps={getRowProps}/>
            <Modal
                onDismiss={() => setShowPropertiesModal(false)}
                isOpen={showPropertiesModal}
                headerSlot={"Properties"}
            >
                <div className="eds-flex eds-flex--direction-column eds-gap--16"
                     style={{maxHeight: "calc(100vh - 304px)"}}>

                    {propertiesMap.size > 0 ?
                        Array.from(propertiesMap).sort((a, b) => a[0].toLowerCase().localeCompare(b[0].toLowerCase())).map(([key, value]) => {
                            return (
                                <div className={"propertyEntry"}>
                                    <span style={{fontWeight: "bold", minWidth: "400px"}} key={key}>{key}:</span>
                                    <span>{value}</span>
                                </div>
                            )
                        })
                        :
                        <></>
                    }
                </div>
            </Modal>
        </div>
    );
}

export {PrintRequestDetailOverviewDocumentEvents};